<template>
  <main class="mx-auto max-w-screen-2xl px-4 py-2 overflow-y-auto mb-32 sm:mb-20">
    <header class="mb-4 flex flex-wrap items-baseline justify-between">
      <h2 class="my-1 text-2xl font-bold text-gray-800">Building performance</h2>
      <p class="text-sm text-gray-800">
        The ventilation parameters of the space and existing mitigations to reduce the risk of viral transmission.
      </p>
    </header>

    <dynamic-form ref="formRef" :form="form" @change="valueChanged" class="mt-4 md:mt-6 p-4 md:p-6 bg-gray-100 rounded-lg">
        <!-- Supply airflow rate -->
      <template v-slot:supplyAirflowRate="{ control, onChange, onFocus, onBlur }">
        <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
          <label for="supply" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3 whitespace-normal">
            Supply airflow rate
          </label>
          <div class="mt-1 sm:mt-0 sm:col-span-1 md:col-span-1">
            <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
              <input
                :id="control.name"
                v-if="control"
                v-model="control.value"
                :name="control.name"
                class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
                placeholder=""
                type="number"
                min=1
                :disabled="isLoadingSuggestedDefaults"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
              >
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-600 sm:text-sm" id=""><!-- Imperial units - SI will be L/s -->
                  {{ flowRate }}
                </span>
              </div>
            </div>
          </div>
          <div class="sm:col-span-1 text-sm text-gray-600 flex justify-center items-end">
            <div v-if="isLoadingSuggestedDefaults" class="flex items-center space-x-2">
              <loading-spinner v-show="isLoadingSuggestedDefaults" class="ml-2 h-4 w-4 filter grayscale"/>
              <span class="text-sm text-gray-600">Loading Suggested defaults...</span>
            </div>
            <div v-else-if="isSuggestedDefault" class="text-sm text-gray-600">Suggested defaults</div>
            <button v-else type="button" class="inline-flex items-center text-pink-500 underline hover:no-underline" @click="resetSuggestedDefaults()">Reset to Suggested defaults</button>
          </div>
          <div class="sm:col-span-3 text-sm text-gray-600">The continuous supply of air that originates from a mechanical system. Typically includes a mixture of outside air (OA) and recirculated air, both of which are filtered and conditioned before entering the space. <a href="https://help.particleone.com/airflow-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" /></svg></a>
          </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
        </div>
      </template>

      <template v-slot:outsideAirRate="{ control, onChange, onFocus, onBlur }">
        <!-- Outside air (OA) -->
        <div class="mb-6">
          <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
            <label for="outside-air" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
              Outside air (OA)
            </label>
            <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">

              <div class="mt-1 relative rounded-md shadow-sm w-48">
                <input
                  :id="control.name"
                  v-if="control"
                  v-model="control.value"
                  :name="control.name"
                  class="focus:ring-pink-500 focus:border-pink-500 block w-full pl-3 pr-13 sm:text-sm border-gray-300 rounded-md disabled:opacity-50"
                  placeholder=""
                  type="number"
                  min=1
                  :disabled="isLoadingSuggestedDefaults"
                  @change="onChange"
                  @focus="onFocus"
                  @blur="onBlur"
                >
                <div class="absolute inset-y-0 right-0 flex items-center">
                  <label for="oa-units" class="sr-only">Units</label>
                  <select
                    id="oa-units"
                    name="oa-units"
                    v-model="oaUnit"
                    class="focus:ring-pink-500 focus:border-pink-500 h-full py-0 pl-2 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                    :disabled="isLoadingSuggestedDefaults"
                  >
                    <option value="unit">{{ flowRate }}</option> <!-- L/s -->
                    <option value="percent">%</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="sm:col-span-3 text-sm text-gray-600">The continuous supply of air introduced into the space from the outside environment. <a href="https://help.particleone.com/outside-air-oa/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg></a></div>
            <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
          </div>
          <div class="w-full border-t border-gray-200"></div>
        </div>
      </template>


    <template v-slot:mechanicalSystem="{ control, onChange, onFocus, onBlur }">
      <!-- Mechanical system type -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="mechanical" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2 whitespace-normal">
          Mechanical system type
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <select
            autocomplete=""
            class="max-w-lg block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option disabled selected value=undefined>Please select</option>
            <option v-for="option in mechanicalSystemOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          The type of HVAC system currently in the space. <a href="https://help.particleone.com/mechanical-system-types-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg></a>
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:filtration="{ control, onChange, onFocus, onBlur }">
      <!-- Filtration -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="filtration" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-2">
          Filtration
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 w-48 max-w-xs">
          <select
            autocomplete=""
            class="block focus:ring-pink-500 focus:border-pink-500 max-w-lg w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
          >
            <option disabled selected value=undefined>Please select</option>
            <option v-for="option in filtrationOptions" :key="option.value" :value="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          The level of filter currently used with the existing mechanical air system to clean the air before it enters the space. <a href="https://help.particleone.com/filtration-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
        </svg></a>
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:temperature="{ control, onChange, onFocus, onBlur }">
      <!-- Temperature -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="temperature" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
          Temperature
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
            <input
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              type="number"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-600 sm:text-sm" id="">
                {{ temperatureUnits }}
              </span>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          The typical temperature in this space.
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:relativeHumidityRate="{ control, onChange, onFocus, onBlur }">
      <!-- Humidity -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5">
        <label for="humidity" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
          Humidity
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
          <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
            <input
              :id="control.name"
              v-if="control"
              v-model="control.value"
              :name="control.name"
              class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
              placeholder=""
              type="number"
              @change="onChange"
              @focus="onFocus"
              @blur="onBlur"
            >
            <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span class="text-gray-600 sm:text-sm" id="">
                %
              </span>
            </div>
          </div>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          The typical relative humidity in this space.
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:barriers="{ control, onChange, onFocus, onBlur }">
      <!-- Barriers - DON'T DISPLAY If social distancing is set to 'YES' on Occupants screen.-->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="barriers" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5">
          Barriers
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
            <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          If occupants are less than 2m (6 feet) apart, physical barriers are in place.
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:airCleaningDevices="{ control, onChange, onFocus, onBlur }">
      <!-- Air cleaning devices -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="air-cleaning" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
          Air cleaning devices
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
            <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          Any portable or standalone devices designed to remove viruses, dust, smoke, and allergens from this space.<a href="https://help.particleone.com/air-cleaning-devices-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
            </svg></a>
          </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:totalCleanAirDeliveryRate="{ control, onChange, onFocus, onBlur }">
      <!-- Total CADR - DON'T DISPLAY IF AIR CLEANING DEVICES IS 'NO'  -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5 mb-6 sm:mb-3">
        <label for="duration" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-3">
          Total CADR
        </label>
          <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2">
            <div class="mt-1 relative rounded-md shadow-sm w-48 max-w-xs">
              <input
                :id="control.name"
                v-if="control"
                v-model="control.value"
                :name="control.name"
                class="focus:ring-pink-500 focus:border-pink-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                placeholder=""
                type="number"
                @change="onChange"
                @focus="onFocus"
                @blur="onBlur"
              >
              <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <span class="text-gray-600 sm:text-sm" id="">
                  {{ flowRate }} <!-- L/s -->
                </span>
              </div>
          </div>
        </div>
          <div class="sm:col-span-3 text-sm text-gray-600">
            Sum total clean air delivery rate (CADR) for the air-cleaning device(s) within the space as specified by the manufacturer(s). If there are multiple values, use the <em>smoke CADR</em>.
          </div>
          <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

    <template v-slot:upperRoomUvgi="{ control, onChange, onFocus, onBlur }">
      <!-- Upper-room UVGI -->
      <div class="grid md:grid-cols-6 gap-2 sm:gap-5">
        <label for="upper-uvgi" class="sm:col-span-1 block text-sm font-medium text-gray-800 sm:pt-0.5 whitespace-normal">
          Upper-room UVGI
        </label>
        <div class="mt-1 sm:mt-0 sm:col-span-3 md:col-span-2 inline-flex">
          <Switch
            :id="control.name"
            v-if="control"
            v-model="control.value"
            :name="control.name"
            @change="onChange"
            @focus="onFocus"
            @blur="onBlur"
            :class="[control.value ? 'bg-pink-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']"
          >
            <span class="sr-only">Use setting</span>
            <span aria-hidden="true" :class="[control.value ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
          </Switch>
          <span class="ml-3" id="">
            <span class="text-sm font-medium text-gray-800">{{ control.value ? 'Yes' : 'No'}}</span>
          </span>
        </div>
        <div class="sm:col-span-3 text-sm text-gray-600">
          An ultraviolet germicidal irradiation (UVGI) system installed above the occupied area. <a href="https://help.particleone.com/upper-room-uvgi-learn-more/" target="_blank" class="inline-flex items-center text-pink-500 underline hover:no-underline">Learn more <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
          </svg></a>
        </div>
        <div class="sm:col-span-1 md:-mt-9 text-red-600 italic text-xs font-medium">{{ validations(currentSpace, control.name ) }}</div><!-- errors -->
      </div>
    </template>

  </dynamic-form>

  </main>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { computed, onMounted, watch } from '@vue/runtime-core';
import { CustomField } from '@asigloo/vue-dynamic-forms';
import { Switch } from '@headlessui/vue';
import {
  ParticleOneMechanicalFilterType,
  ParticleOneMechanicalSystemType,
  ParticleOneUnit
} from '@/network/models/ParticleOneCreateSpaceRequest';
import { validations } from '@/models/create-space-mapping';
import spaceApiService from '@/network/services/space';
import { useStore } from 'vuex';
import LoadingSpinner from '@/components/spinners/LoadingSpinner.vue';

export default {
  name: 'SpaceFormPageFour',
  components: {
    Switch,
    LoadingSpinner
  },
  computed: {
    mechanicalSystemOptions() {
      return Object.entries(ParticleOneMechanicalSystemType).map(([key, value]) => ({ label: key, value: value }));
    },
    filtrationOptions() {
      return Object.entries(ParticleOneMechanicalFilterType).map(([key, value]) => ({ label: key, value: value }));
    },
    flowRate() {
      switch (this.currentSpace.units) {
      case ParticleOneUnit.Imperial: return 'CFM';
      case ParticleOneUnit['International System of Units (SI)']: return 'L/s';
      default: return 'CFM';
      }
    },
    temperatureUnits() {
      return this.currentSpace.units === ParticleOneUnit.Imperial ? '˚F' : '˚C';
    }
  },
  props: {
    currentSpace: {
      type: Object,
      required: true
    }
  },
  setup(props, context) {
    const formValues = reactive({});
    const initialValues = {
      supplyAirflowRate: props.currentSpace.supply_airflow_rate,
      outsideAirRate: props.currentSpace.outside_air_rate,
      mechanicalSystem: props.currentSpace.mechanical_system ?? 'mixing_high',
      filtration: props.currentSpace.filtration ?? 'merv6',
      temperature: props.currentSpace.temperature,
      relativeHumidityRate: props.currentSpace.relative_humidity_rate,
      barriers: props.currentSpace.barriers ?? false,
      airCleaningDevices: props.currentSpace.air_cleaning_devices ?? false,
      totalCleanAirDeliveryRate: props.currentSpace.total_clean_air_delivery_rate,
      upperRoomUvgi: props.currentSpace.upper_room_uvgi ?? false,
    };
    const oaUnit = ref('unit');
    const isLoadingSuggestedDefaults = ref(false);
    const store = useStore();
    const spaceId = ref(store.getters.currentSpace?.id);
    const isSuggestedDefault = computed(() => inputValuesMatchSuggestedComputedValues(oaUnit.value));
    const defaultSupplyAirflowRate = ref(null);
    const defaultOutsideAirRate = ref(null);
    const supplyAirflowRate = ref(initialValues.supplyAirflowRate);
    const outsideAirRate = ref(initialValues.outsideAirRate);

    const form = computed(() => ({
      id: 'space-creation-page-four',
      fields: {
        supplyAirflowRate: CustomField({
          value: supplyAirflowRate.value
        }),
        outsideAirRate: CustomField({
          value: outsideAirRate.value
        }),
        mechanicalSystem: CustomField({
          value: initialValues.mechanicalSystem,
        }),
        filtration: CustomField({
          value: initialValues.filtration,
        }),
        temperature: CustomField({
          value: initialValues.temperature,
        }),
        relativeHumidityRate: CustomField({
          value: initialValues.relativeHumidityRate,
        }),
        barriers: CustomField({
          value: initialValues.barriers,
          customClass: `${props.currentSpace.social_distancing ? 'hidden' : 'visible'}`
        }),
        airCleaningDevices: CustomField({
          value: initialValues.airCleaningDevices,
        }),
        totalCleanAirDeliveryRate: CustomField({
          value: initialValues.totalCleanAirDeliveryRate,
          customClass: `${formValues.airCleaningDevices ? 'visible' : 'hidden'}`
        }),
        upperRoomUvgi: CustomField({
          value: initialValues.upperRoomUvgi,
        })
      }
    }));

    function valueChanged(values) {
      Object.assign(formValues, { ...values, oaUnit: oaUnit.value });
      context.emit('formValues', formValues);
      if (formValues['supplyAirflowRate'] != null) {
        supplyAirflowRate.value = parseFloat(formValues['supplyAirflowRate']);
      }

      if (formValues['outsideAirRate'] != null) {
        outsideAirRate.value = parseFloat(formValues['outsideAirRate']);
      }
    }

    function calculateOutsideAir(outsideAirRate, supplyAirflowRate, unit) {
      if (unit === 'unit') {
        return parseFloat(outsideAirRate);
      }
      return (parseFloat(outsideAirRate) / parseFloat(supplyAirflowRate)) * 100;
    }

    async function getSuggestedDefaults() {
      const defaults = await spaceApiService.getSuggestedFlowRate(spaceId.value);
      defaultSupplyAirflowRate.value = defaults.flow_rates.zone1.supply;
      defaultOutsideAirRate.value = defaults.flow_rates.zone1.exhaust;
    }

    async function resetSuggestedDefaults() {
      supplyAirflowRate.value = defaultSupplyAirflowRate.value;
      outsideAirRate.value = calculateOutsideAir(defaultOutsideAirRate.value, defaultSupplyAirflowRate.value, oaUnit.value);
    }

    function inputValuesMatchSuggestedComputedValues(unit) {
      const outsideAir = parseFloat(formValues['outsideAirRate']);
      const supplyAir = parseFloat(formValues['supplyAirflowRate']);
      if (unit === 'unit') {
        return supplyAir === defaultSupplyAirflowRate.value && outsideAir === defaultOutsideAirRate.value;
      }
      return closelyMatchNumber(supplyAir, defaultSupplyAirflowRate.value) && closelyMatchNumber(outsideAir, calculateOutsideAir(defaultOutsideAirRate.value, defaultSupplyAirflowRate.value, unit));
    }

    function closelyMatchNumber(n1, n2) {
      return Math.abs(n1 - n2) < Number.EPSILON;
    }

    function outsideAirValue(previousUnit, formValues, supplyAirflow) {
      if (inputValuesMatchSuggestedComputedValues(previousUnit)) {
        return previousUnit === 'percent' ? defaultOutsideAirRate.value : formValues['outsideAirRate'];
      }
      return previousUnit === 'percent' ? parseFloat(parseFloat(formValues['outsideAirRate']) / 100) * supplyAirflow : formValues['outsideAirRate'];
    }

    onMounted(async () => {
      isLoadingSuggestedDefaults.value = true;
      await getSuggestedDefaults();
      if (supplyAirflowRate.value == null && outsideAirRate.value == null) {
        resetSuggestedDefaults();
      }
      isLoadingSuggestedDefaults.value = false;
    });

    watch(oaUnit, (unit, previousUnit) => {
      if (previousUnit === unit) return;
      const supplyAirflow = parseFloat(formValues['supplyAirflowRate']);
      const outsideAir = outsideAirValue(previousUnit, formValues, supplyAirflow);
      supplyAirflowRate.value = supplyAirflow;
      outsideAirRate.value = calculateOutsideAir(outsideAir, supplyAirflow, unit);
      formValues['supplyAirflowRate'] = supplyAirflowRate.value;
      formValues['outsideAirRate'] = outsideAirRate.value;
    });

    return {
      form,
      formValues,
      valueChanged,
      oaUnit,
      validations,
      isLoadingSuggestedDefaults,
      resetSuggestedDefaults,
      isSuggestedDefault,
    };
  }
};
</script>